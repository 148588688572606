import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService } from './services/auth.service';
import { MenuPage } from './template/menu/menu.page';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'app/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'app',
    component: MenuPage,

    children:[
      {
        path:'users',
        loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
      },
      {
        path: 'masters',
        loadChildren: () => import('./masters/masters.module').then( m => m.MastersPageModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
      },
      {
        path: 'employees',
        loadChildren: () => import('./employees/employees.module').then( m => m.EmployeesPageModule)
      },
      {
        path: 'purchases',
        loadChildren: () => import('./purchases/purchases.module').then( m => m.PurchasesPageModule)
      },
      {
        path: 'payments',
        loadChildren: () => import('./payments/payments.module').then( m => m.PaymentsPageModule)
      },
      {
        path: 'day-open-close',
        loadChildren: () => import('./day-open-close/day-open-close.module').then( m => m.DayOpenClosePageModule)
      },
      {
        path: 'work-allocation',
        loadChildren: () => import('./work-allocation/work-allocation.module').then( m => m.WorkAllocationPageModule)
      },
      {
        path: 'production',
        loadChildren: () => import('./production/production.module').then( m => m.ProductionPageModule)
      },
      {
        path: 'salary',
        loadChildren: () => import('./salary/salary.module').then( m => m.SalaryPageModule)
      },
      {
        path: 'hire-entry',
        loadChildren: () => import('./hire-entry/hire-entry.module').then( m => m.HireEntryPageModule)
      },
      {
        path: 'menu',
        loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
      },
      {
        path: 'sales',
        loadChildren: () => import('./sales/sales.module').then( m => m.SalesPageModule)
      },
      {
        path: 'stocks',
        loadChildren: () => import('./stocks/stocks.module').then( m => m.StocksPageModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
      },
      {
        path: 'analysis',
        loadChildren: () => import('./analysis/analysis.module').then( m => m.AnalysisPageModule)
      },
    ],canActivate:[AuthService]
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'camera-picture',
    loadChildren: () => import('./utils/camera-picture/camera-picture.module').then( m => m.CameraPicturePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
